import * as Bootstrap from "bootstrap";


// Language: javascript
function getModal(id) {
    return new Bootstrap.Modal(document.getElementById(id), {
        keyboard: true,
        backdrop: false
    })
}

export function showModal(id) {
    let modal = getModal(id)
    modal.show()
}

export function hideModal(id) {
    document.getElementById(id).style.display = 'None';
}

export async function generateUUIDFromEmail(email) {
    const encoder = new TextEncoder();
    const data = encoder.encode(email);
    const hashBuffer = await crypto.subtle.digest('SHA-256', data);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hexString = hashArray.map(byte => byte.toString(16).padStart(2, '0')).join('');
    return `${hexString.slice(0, 8)}-${hexString.slice(8, 12)}-${hexString.slice(12, 16)}-${hexString.slice(16, 20)}-${hexString.slice(20, 32)}`;
}


export function validatePassword(password, confirmPassword) {
    let errors = [];
    if (password !== confirmPassword) {
        errors.push("Confirm password must be equal to password")
    }
    if (password.length < 8) {
        errors.push("Your password must be at least 8 characters");
    }
    if (password.length > 16) {
        errors.push("Your password must be at most 16 characters");
    }
    if (password.search(/[a-z]/i) < 0) {
        errors.push("Your password must contain at least one letter.");
    }
    if (password.search(/[0-9]/) < 0) {
        errors.push("Your password must contain at least one digit.");
    }
    if (password.search(/[a-z]/) < 0) {
        errors.push("Your password must contain at least one lowercase letter.")
    }
    if (password.search(/[A-Z]/) < 0) {
        errors.push("Your password must contain at least one uppercase letter.")
    }
    return errors
}

export function validateIsEmail(email) {
    let errors = [];
    if (email.search('@') < 0) {
        errors.push("Your email must contain an @ symbol.")
    }
    if (email.search('.') < 0) {
        errors.push("Your email must contain a .com.")
    }

    if (email.length < 6) {
        errors.push("Your email must be at least 6 characters.")
    }

    if (email.length > 50) {
        errors.push("Your email must be at most 50 characters.")
    }

    if (email.search(/[a-z]/i) < 0) {
        errors.push("Your email must contain at least one letter.")
    }

    return errors
}