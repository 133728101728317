import Vue from 'vue'
import Vuex from 'vuex'
import actions from "@/store/actions";

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        user: null,
        isLoggedIn: false,
        editFamilyMember: {},
        activePageView: 'signin',
        potentialParents: [],
        defaultFamily: {},
        family: {},
        defaultEditFamilyMember: {
            firstname: '',
            middlename: '',
            lastname: '',
            gender: '',
            location: '',
            files: [],
            mid: "",
            fid: "",
            pids: [],
            tribe: '',
            dateOfBirth: '',
            ethnicity: '',
            clan: '',
            yearBirth: '',
            yearDeath: '',
            notes: '',
            livingStatus: 'alive',
            updatedOn: new Date().toISOString(),
        },
        defaultFamilyMember: {
            firstname: '',
            middlename: '',
            lastname: '',
            yearBirth: '',
            yearDeath: '',
            gender: '',
            location: '',
            tribe: '',
            ethnicity: '',
            dateOfBirth: '',
            mid: "",
            fid: "",
            pids: [],
            clan: '',
            files: [],
            notes: '',
            livingStatus: 'alive',
            createdOn: new Date().toISOString()

        }
    },
    getters: {
        'auth/user': state => state.user,
        'auth/checkLoggedIn': state => {
            return state.isLoggedIn
        },
        'members/getEditFamilyMember': state => {
            return state.editFamilyMember
        },
        'members/getPotentialParents': state => {
            return state.potentialParents
        }
    },
    mutations: {
        'auth/setCurrentUser': (state, user) => {
            state.user = user
        },
        'auth/setLoggedIn': (state, isLoggedIn) => {
            state.isLoggedIn = isLoggedIn
        },
        'members/setMember': (state, member) => {
            state.editFamilyMember = member
        },
        'members/setEditFamilyMember': (state, editFamilyMember) => {
            state.editFamilyMember = editFamilyMember
        },
        'members/setPotentialParents': (state, potentialParents) => {
            state.potentialParents = potentialParents
        },
        'admin/setFamily': (state, family) => {
            state.family = family
        },
    },
    actions: {
        ...actions
    },
    modules: {}
})
