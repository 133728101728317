<template>
  <div class="modal centered" id="share-family-modal" data-bs-backdrop="static" tabindex="-1"
       aria-labelledby="add-family-modal" aria-hidden="true">
    <div class="modal-dialog text-start">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Share family</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <form>
            <div class="form-group p-1">
              <label for="name">Email Address</label>
              <input type="email" class="form-control" placeholder="Email address" v-model="invite.email">
            </div>
            <div class="form-group p-1">
              <label for="description">Message</label>
              <textarea type="text" class="form-control" placeholder="Message(optional)"
                        v-model="invite.message"> </textarea>
            </div>
            <div class="p-1 text-start">
              <label for="permissions">Permissions</label>
            </div>
            <div class="d-flex flex-row justify-content-between p-1">
              <div class="form-check">
                <input class="form-check-input" type="checkbox" value="" id="view" v-model="invite.permissions.view">
                <label class="form-check
                -label" for="view">
                  View
                </label>
              </div>

              <div class="form-check">
                <input class="form-check-input" type="checkbox" value="" id="edit" v-model="invite.permissions.edit">
                <label class="form-check-label" for="edit">
                  Edit
                </label>
              </div>
              <div class="form-check">
                <input class="form-check-input" type="checkbox" value="" id="delete"
                       v-model="invite.permissions.delete">
                <label class="form-check-label" for="delete">
                  Delete
                </label>
              </div>

            </div>
          </form>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
          <button type="button" class="btn btn-primary" @click="shareFamily">Send</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {generateUUIDFromEmail, hideModal, validateIsEmail} from "@/utils/common";

export default {
  name: "shareFamilyModal",
  props: {
    propFamily: {
      type: Object,
      required: false
    }
  },
  watch: {
    propFamily: function (newVal) {
      this.family = newVal;
    }
  },
  data() {
    return {
      family: this.$store.state.family,
      invite: {
        email: '',
        message: '',
        permissions: {
          view: true,
          edit: false,
          delete: false
        },
        createOn: new Date().toISOString(),
        createdBy: this.$store.state.user.id,
      }
    }
  },
  methods: {

    async checkDuplicateFamilies(families, familyId) {
      let duplicate = false
      families.forEach(family => {
        if (familyId === family
            .id) {
          duplicate = true
        }
      })
      return duplicate
    },
    async shareFamily() {
      let errors = validateIsEmail(this.invite.email)
      if (errors.length > 0) {
        this.$toast.error(errors[0]);
        return false;
      }

      let adminId = await generateUUIDFromEmail(this.invite.email)
      let familiesData = await this.$store.dispatch('admin/getAdminFamilies', adminId)
      let families
      if (Object.keys(familiesData).length === 0) {
        families = [
          {
            id: this.family.id,
            name: this.family.name,
            location: this.family.location,
            description: this.family.description,
            permissions: this.invite.permissions
          }
        ]
      } else {
        families = familiesData.families
        if (!await this.checkDuplicateFamilies(families, this.family.id)) {
          families.push({
            id: this.family.id,
            name: this.family.name,
            location: this.family.location,
            description: this.family.description,
            permissions: this.invite.permissions
          })
        } else {
          this.$toast.error('Family already shared with this user')
          hideModal('share-family-modal')
          return false
        }

      }
      await this.$store.dispatch('admin/addFamilyAdmin', {id: adminId, families: families})
      this.$toast.success('Family shared successfully')
      hideModal('share-family-modal')

    }
  }


}
</script>
<style scoped>

</style>