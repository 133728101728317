import DatabaseDAO from "@/services/base/DatabaseDAO";
import FamilyAdmins from "@/services/api/familyAdmins";

let uuid = require('uuid');

class Family {
    constructor() {
        this.recordUrl = `family`
    }

    async createFamily(data) {
        if (data.id === undefined) {
            data.id = uuid.v4()
        }
        let familiesData = await FamilyAdmins.getAdminFamilies(data.createdBy);
        let families = familiesData?.families || []
        if (families.length > 0) {
            let familyNames = families.map(family => family.name)
            if (familyNames.includes(data.name)) {
                this.$toast.info("Family name already exists")
                return {error: "Family name already exists"}
            }
            families.push(
                {
                    id: data.id,
                    name: data.name,
                    permissions: {
                        read: true,
                        write: true,
                        delete: true
                    }
                }
            )

        } else {
            families = [
                {
                    id: data.id,
                    name: data.name,
                    permissions: {
                        read: true,
                        write: true,
                        delete: true
                    }
                }]
        }
        let familyAdmin = {
            id: data.createdBy,
            families: families
        }

        await FamilyAdmins.createFamilyAdmin(familyAdmin)
        return await DatabaseDAO.addRecord(data, this.recordUrl)
    }

    async getFamily(id) {
        // call database service to get account
        // return account object
        return await DatabaseDAO.getRecord(id, this.recordUrl)
    }

    async updateFamily(data) {
        // call database service to update account
        // return account object
        let dataId = data.id
        return await DatabaseDAO.updateRecord(dataId, this.recordUrl, data)
    }

    async deleteFamily(id) {
        // call database service to update account
        // return account object
        return await DatabaseDAO.deleteRecord(id, this.recordUrl)
    }

    async getFamiliesByAdminId(adminId) {
        let familiesData = await FamilyAdmins.getAdminFamilies(adminId);

        let families = familiesData?.families || [];
        if (families.length === 0) {
            console.warn("No families found for admin ID:", adminId);
            return []
        }
        let familyIds = new Set(families.map(family => family.id));

        return await Promise.all(
            Array.from(familyIds)
                .map(async familyId => {
                    let family = await this.getFamily(familyId);
                    if (family.name !== undefined) {
                        return family;
                    }
                })
                .filter(Boolean)
        );
    }

}

export default new Family();
