<template>
  <div class="home">
    <auth-nav-bar></auth-nav-bar>

    <div class="d-flex justify-content-between m-3">
      <div>
        <button class="btn btn-outline-primary" @click="gotToFamilies">Back</button>
      </div>
      <h2 class="text-uppercase">{{ family.name }}'s family</h2>
    </div>
    <hr>
    <div id="tree" ref="tree">
    </div>
  </div>

</template>

<script>

// eslint-disable-next-line
import FamilyTree from "../assets/js/FamilyTree.js";
import AuthNavBar from "@/components/navigation/AuthNavBar.vue";
import * as uuid from "uuid";

FamilyTree.templates.tommy_female.defs =
    `<g transform="matrix(0.05,0,0,0.05,-12,-9)" id="heart">
        <path fill="#F57C00" d="M438.482,58.61c-24.7-26.549-59.311-41.655-95.573-41.711c-36.291,0.042-70.938,15.14-95.676,41.694l-8.431,8.909  l-8.431-8.909C181.284,5.762,98.663,2.728,45.832,51.815c-2.341,2.176-4.602,4.436-6.778,6.778 c-52.072,56.166-52.072,142.968,0,199.134l187.358,197.581c6.482,6.843,17.284,7.136,24.127,0.654 c0.224-0.212,0.442-0.43,0.654-0.654l187.29-197.581C490.551,201.567,490.551,114.77,438.482,58.61z"/>
    <g>`;
FamilyTree.templates.tommy_male.defs =
    `<g transform="matrix(0.05,0,0,0.05,-12,-9)" id="heart">
        <path fill="#F57C00" d="M438.482,58.61c-24.7-26.549-59.311-41.655-95.573-41.711c-36.291,0.042-70.938,15.14-95.676,41.694l-8.431,8.909  l-8.431-8.909C181.284,5.762,98.663,2.728,45.832,51.815c-2.341,2.176-4.602,4.436-6.778,6.778 c-52.072,56.166-52.072,142.968,0,199.134l187.358,197.581c6.482,6.843,17.284,7.136,24.127,0.654 c0.224-0.212,0.442-0.43,0.654-0.654l187.29-197.581C490.551,201.567,490.551,114.77,438.482,58.61z"/>
    <g>`;

FamilyTree.elements.textarea = function (data, editElement, minWidth, readOnly) {
  let id = FamilyTree.elements.generateId();
  let value = data[editElement.binding];
  if (value === undefined) value = '';
  if (readOnly && !value) {
    return {
      html: ''
    };
  }
  return {
    html: `
        <div class="bft-form-field" style="min-width: 280px">
            <div class="bft-input">
                <label for="${id}">${editElement.label}</label>
                <textarea name="${id}" style="width: 100%" class="form-control m-1 w-100" id="${id}" rows="3" data-binding="${editElement.binding}">${value}</textarea>
            </div>
          </div>
        `,
    id: id,
    value: value
  };

};

export default {

  name: 'FamilyMembersTreeView',
  components: {AuthNavBar},
  computed: {
    familyId() {
      return this.$route.params.id;
    }
  },
  data() {
    return {
      familyMembers: [
        {
          firstname: '',
          middlename: '',
          lastname: '',
          yearBirth: '',
          yearDeath: '',
          gender: '',
          location: '',
          tribe: '',
          ethnicity: '',
          dateOfBirth: '',
          mid: "",
          fid: "",
          pids: [],
          clan: '',
          files: [],
          notes: '',
          livingStatus: 'alive',
          createdOn: new Date().toISOString()
        }
      ],
      familyTree: null,
      family: {}
    }
  },

  methods: {
    familyTreeSetup(id, members) {
      console.log(members)
      this.familyTree = new FamilyTree(id, {
        searchDisplayField: "name",
        zoom: {speed: 130, smooth: 10},
        nodeTreeMenu: true,
        enableSearch: true,
        scaleInitial: FamilyTree.match.boundary,
        nodeMouseClick: FamilyTree.action.edit,
        // orientation: FamilyTree.orientation.top,
        miniMap: false,
        padding: 20,
        zoomScale: 0.2,
        nodes: members,
        showXScroll: false,
        showYScroll: false,
        nodeMenu: {
          edit: {text: "Edit"},
        },
        mouseScroll: FamilyTree.action.none,
        editForm: {
          buttons: {
            pdf: null,
            share: null
          },
          addMore: null,
          saveAndCloseBtn: 'Save',
          generateElementsFromFields: false,
          elements: [
            {type: 'textbox', label: 'First Name', binding: 'firstname', vlidators: {required: 'Is required'}},
            {type: 'textbox', label: 'Last  Name', binding: 'lastname', vlidators: {required: 'Is required'}},
            {
              type: 'select', label: 'Gender', binding: 'gender', options: [
                {value: 'male', text: 'Male'},
                {value: 'female', text: 'Female'}
              ]
            },
            {
              type: 'select', options: [
                {value: 'alive', text: 'Alive'},
                {value: 'dead', text: 'Dead'}],
              label: 'Living status', binding: 'livingStatus', vlidators: {required: 'Is required'}
            },
            {
              type: 'select',
              label: 'Ethnicity',
              binding: 'ethnicity',
              options: [
                {value: 'white', text: 'White or Caucasian'},
                {value: 'black', text: 'Black or African American'},
                {value: 'asian', text: 'Asian'},
                {value: 'hispanic', text: 'Hispanic'},
                {value: 'native_american', text: 'Native American'},
                {value: 'other', text: 'Other'}
              ]
            },
            {type: 'textbox', label: 'Location', binding: 'location'},
            {type: 'textbox', label: 'Tribe', binding: 'tribe'},
            {type: 'textbox', label: 'Ethnicity', binding: 'ethnicity'},


            {type: 'textarea', label: 'Notes', binding: 'notes'},
          ]
        },
        toolbar: {
          layout: true,
          zoom: true,
          fit: true,
          fullScreen: true
        },
        nodeBinding: {
          field_0: "firstname",
          field_1: "lastname",

        },
        menu: {
          pdf: {text: "Export PDF"},
          csv: {text: "Export CSV"},
        }
      });
    },
    async getFamilyMembers() {
      this.familyMembers = await this.$store.dispatch('members/getFamilyMembers', this.$route.params.id)
      if (this.familyMembers.length === 0) {
        console.log('No family members found, adding a default member');

        let id = uuid.v4();
        this.familyMembers.push({
          id: id,
          firstname: 'John',
          middlename: '',
          lastname: 'Doe',
          gender: '',
          location: '',
          mid: "",
          fid: "",
          pids: [],
          tribe: '',
          dateOfBirth: '',
          ethnicity: '',
          clan: '',
          yearBirth: '',
          yearDeath: '',
          notes: '',
          livingStatus: 'alive',
          updatedOn: new Date().toISOString(),
        });
      }
    },
    async getFamily() {
      await this.$store.dispatch('family/getFamily', this.$route.params.id).then(family => {
        this.family = family
      })
    },
    gotToFamilies() {
      this.$router.push({name: 'families-list'})
    },

    async addFamilyMember(familyMember) {
      let member = familyMember[0];
      member.familyId = this.familyId;
      if (member.firstname === '' || member.lastname === '') {
        return;
      }
      await this.$store.dispatch('members/addMember', member);
      this.$toast.success('Family member created successfully');
      console.log(member)
    },
  },
  async mounted() {
    await this.getFamily()
    await this.getFamilyMembers()
    this.familyTreeSetup(this.$refs.tree, this.familyMembers)
    this.familyTree.onUpdateNode((args) => {
      this.addFamilyMember(args.updateNodesData)
    })
  }
}
</script>

<style scoped>
#tree {
  width: 100%;
  height: 100%;
}
</style>