// import {generateHash} from "@/utils/HashUtil";
// //
// // generateHash


import DatabaseDAO from "@/services/base/DatabaseDAO";

class FamilyAdmins {
    constructor() {
        this.recordUrl = `admins`
    }

    async createFamilyAdmin(data) {
        return await DatabaseDAO.addRecord(data, this.recordUrl)
    }

    async getAdminFamilies(adminId) {
        return await DatabaseDAO.getRecord(adminId, this.recordUrl)

    }

}

export default new FamilyAdmins();
