import DatabaseDAO from "@/services/base/DatabaseDAO";

class Members {
    constructor() {
        this.recordUrl = `members`
    }

    async createMember(data) {
        // call database service to create account
        // return account object

        return await DatabaseDAO.addRecord(data, this.recordUrl)
    }

    async getMember(id) {
        // call database service to get account
        // return account object
        return await DatabaseDAO.getRecord(id, this.recordUrl)
    }

    async updateMember(data) {
        // call database service to update account
        // return account object
        let dataId = data.id
        return await DatabaseDAO.updateRecord(dataId, this.recordUrl, data)
    }

    async deleteMember(id) {
        // call database service to update account
        // return account object
        return await DatabaseDAO.deleteRecord(id, this.recordUrl)
    }

    async getMembers() {
        // call database service to update account
        // return account object
        return Object.values(await DatabaseDAO.getRecords(this.recordUrl))
    }
    async getFamilyMembers(familyId) {
        // call database service to update account
        // return account object
        let members = await this.getMembers()
        return members.filter(member => member.familyId === familyId)
    }
}

export default new Members();
