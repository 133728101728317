<template>
  <div>
    <table class="table table-striped">
      <thead>
      <tr>
        <th scope="col">#</th>
        <th scope="col">Name</th>
        <th scope="col">Description</th>
        <th scope="col">Location</th>
        <th scope="col">Actions</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(family, index) in families" :key="family.id">
        <th scope="row">{{ index + 1 }}</th>
        <td>{{ family.name }}</td>
        <td>{{ family.description }}</td>
        <td>{{ family.location }}</td>
        <td>
          <button class="btn btn-primary" @click="editFamily(family)">
            <i class="bi bi-pencil-square"></i>
          </button>
          <button class="btn btn-outline-primary" @click="viewFamily(family, 'tree')">
            <i class="bi bi-eye"></i>
          </button>
          <button class="btn btn-secondary" @click="shareFamily(family)">
            <i class="bi bi-share"></i>
          </button>
          <button class="btn btn-danger" @click="deleteFamily(family)">
            <i class="bi bi-trash"></i>
          </button>
        </td>
      </tr>
      </tbody>
    </table>

  </div>

</template>

<script>

export default {
  name: "FamilyList",
  components: {},
  props: {
    families: {
      type: Array,
      required: true
    },
  },
  data() {
    return {
      family: {}
    }
  },

  methods: {
    editFamily(family) {
      this.$emit('edit-family', family)
    },
    viewFamily(family, mode) {
      this.$emit('view-family', family, mode)
    },
    deleteFamily(family) {
      let deleteFamily = confirm('Are you sure you want to delete this family?');
      if (deleteFamily) {
        this.$emit('delete-family', family)
      }

    }
    , shareFamily(family) {
      this.$emit('share-family', family)

    }
  }
}
</script>

<style scoped>

</style>
